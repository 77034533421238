.hoox-sticky-bar-wrapper {
    position: fixed;
    left: 0;
    bottom: -70px;
    width: 100%;
    z-index: 99;
    background: linear-gradient(180deg, rgba(240, 228, 234, 0.00) 0%, #F0E4EA 100%);
    transition: .3s;
    display: block;
    padding: 8px 25px 16px;
    box-sizing: border-box;
}

.hoox-sticky-bar {
    border-radius: unset;
}

.hoox-sticky-bar-wrapper.active {
    bottom: 0;
}

@media (min-width: 768px) {
    .hoox-sticky-bar-wrapper {
        padding: unset;
        background: unset;
        display: none;
    }
}

.hoox-sticky-bar-wrapper-lp-3 {
    background: var(--hoox-color-white);
    border-top: 1px solid var(--hoox-color-slate-medium);
    padding: 8px 10px 12px;
}

.hoox-sticky-bar-wrapper-lp-3 .hoox-btn {
    height: 40px;
}

.hoox-sticky-bar-wrapper-lp-2 {
    background: none;
    border: none;
    width: 100%;
    padding: 0;
}
